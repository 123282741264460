<template>
  <div class="about">
    <div class="mapOfflineBox">
      <MapOfflineContianer />
    </div>
  </div>
</template>
<script>
import MapOfflineContianer from "./MapOffline.vue"
export default {
  name: "Home",
  components: {
    MapOfflineContianer
  },
};
</script>
<style scoped>

</style>